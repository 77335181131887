import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Snarvei for å opprette oppgaver på Mac" />
    <div className="longtext">
      <h2>Snarvei for å opprette oppgaver på Mac</h2>
      <p>
        <b>Gjelder kun for macOS.</b>
      </p>

      <h3>Steg 1: Installer MenteHelper</h3>
      <p>
        Det er nødvendig med et lite tilleggsprogram for at snarveien skal
        fungere:
      </p>

      <p>
        <a href="https://www.mente.app/MenteHelper.zip">Last ned MenteHelper</a>
      </p>

      <p>
        Unzip, dra appen til Applications og start den derfra etter nedlasting.
      </p>

      <h3>Steg 2: Sett opp snarveien</h3>
      <ol>
        <li>
          Start MenteHelper. Du vil se et lite M-ikon oppe til høyre ved
          klokken. Trykk på dette og deretter Innstillinger.
        </li>
        <li>
          Huk av for "Start ved login", slik at snarveien fungerer selv om du
          starter maskinen på nytt
        </li>
        <li>
          Som standard er snarveien <b>Ctrl + Space</b>. Du kan eventuelt endre
          denne ved å klikke i det hvite feltet og taste inn en ny.
        </li>
      </ol>

      <h3>Steg 3: Test snarveien</h3>
      <ol>
        <li>
          Bruk snarveien (<b>Ctrl + Space</b> som standard) i hvilket som helst
          program.
        </li>
        <li>Noter ned oppgaven og trykk "Send til Mente".</li>
        <li>I Mente vil du finne igjen oppgaven i "Innboks".</li>
      </ol>

      <h3>Trøbbel?</h3>
      <h4>Snarveien fungerer ikke</h4>
      <ul>
        <li>
          Sjekk at MenteHelper er startet (du skal se et M-ikon oppe ved
          klokken). Hvis ikke, gå til Applications og start MenteHelper derfra.
        </li>
        <li>
          Sjekk at snarveien du har valgt ikke overstyres av en annen app/MacOS.
          I så fall må du fjerne den der eller velge en annen snarvei i
          MenteHelper.
        </li>
      </ul>

      <h4>Oppgavene blir ikke opprettet?</h4>
      <ul>
        <li>Oppgavene havner i Innboksen i Mente. Har du sjekket der?</li>
        <li>
          Mente-appen må være åpen (i tillegg til MenteHelper) for at oppgavene
          skal opprettes. Sjekk at både Mente og MenteHelper kjører på macen og
          prøv igjen.
        </li>
        <li>
          Sjekk at du har nyeste versjon av Mente (lastet ned fra Mac App Store)
          og MenteHelper (se link lenger opp på denne siden)
        </li>
      </ul>

      <h3>Se også:</h3>
      <p>
        <Link to="/help/keyboard-shortcuts">Tastatursnarveier</Link>
      </p>
    </div>
  </Layout>
)

export default Page
